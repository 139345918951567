<template>
<div>
    <b-modal class="upload-file-modal" :id="id" hide-footer hide-header>
        <preloader v-if="useLoader" :type="'modal'" />
        <div class="d-block text-center m-3" v-if="!useLoader">
          <img :src="`/assets/images/${iconName}`">
          <h3 class="my-3 openSans-100 fz-24">{{ title }}</h3>
          <b-form-file
            multiple
            size="lg"
            v-model="files"
            :placeholder="$t('ingestionProject.cards.card.uploadFile.placeholder')"
            :drop-placeholder="$t('ingestionProject.cards.card.uploadFile.dropPlaceholder')"
            @input="submitFile"></b-form-file>
        </div>
      </b-modal>
</div>
 
</template>

<script>
import IngestionService from '@/modules/common/services/IngestionService.js'
import Preloader from '@/modules/common/components/Preloader.vue'

export default {

  name: 'UploadFileModal',
  components:{
    'preloader': Preloader
  },
  data () {
    return {
      files: [],
      useLoader: false,
    }
  },
  props: {
    id: String,
    iconName: String,
    title: String,
    callback: Function,
    projectId: String
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(this.id)
    },
    submitFile() {
      this.useLoader = true
      var formData = new FormData()
      this.files.forEach(file => {
        formData.append('files', file, file.name)
      })
      IngestionService.uploadProjectFiles(formData, this.projectId).then(response => {
        let data = response.data
        this.callback(data.project_id, data.files)
        this.hideModal()
        this.useLoader = true;
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>