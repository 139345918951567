<template>
<div>
  <b-modal class="upload-file-modal" :id="id" @hidden="resetModal" hide-footer hide-header>
    <preloader v-if="useLoader" :type="'modal'" />
    <div class="d-block text-center m-3" v-if="!useLoader">
      <img :src="`/assets/images/${iconName}`">
      <h3 class="my-3 openSans-100 fz-24">{{ title }}</h3>
      <b-form-input v-model="link"
                    :placeholder="$t('ingestionProject.cards.card.pdfUrl.modal.placeholder')">
      </b-form-input>
      <b-button class="m-4 button-primary" @click="submit">
        {{ $t('ingestionProject.cards.card.pdfUrl.modal.btnUpload') }}
      </b-button>
    </div>
  </b-modal>
</div>
 
</template>

<script>
import IngestionService from '@/modules/common/services/IngestionService.js'
import Preloader from '@/modules/common/components/Preloader.vue'

export default {

  name: 'UrlFileModal',
  components:{
    'preloader': Preloader
  },
  data () {
    return {
      files: [],
      link: '',
      useLoader: false,
    }
  },
  props: {
    id: String,
    iconName: String,
    title: String,
    callback: Function
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(this.id)
      this.link = ''
    },
    submit() {
      this.useLoader = true
      IngestionService.uploadFileFromUrl(this.link).then(response => {
        let data = response.data
        this.callback(data.project_id, data.files, this.link)
        this.hideModal()
        this.useLoader = true
      })
      .catch((error) => {
        console.log(error)
      })
    },
    resetModal() {
      this.link = ''
    }
  }
}
</script>