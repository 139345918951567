import Repository from '@/modules/common/utils/Repository.js'
import store from '@/store'

export default {
  testIngestion(manifest) {
    let sources = []
    manifest.forEach(source => {
      var current = Object.assign({}, source)
      current["first_page"] = 1
      current["last_page"] = 2
      current["credential"] = {
        type: "headers",
        content: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }
      sources.push(current)
    })
    return Repository.dbService.post('ingestion/test', {
      manifest: {
        sources: sources
      }
    })
  }
}