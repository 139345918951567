<template>
  <div>
    <b-row v-if="currentStep == 1">
      <b-col>
        <div v-if="creationMode" class="my-4">
          <h3 class="text-left openSans-100 fz-22 new-ingestion-proyect-name">{{ $t('ingestionProject.form.labelNameProject') }}</h3>
          <b-row class="my-3">
            <b-col cols="3">
              <b-form-input class="new-ingestion-proyect-name-input openSans-100 fz-18"
                            v-model="currentProject.name" size="lg" autofocus
                            :placeholder="$t('ingestionProject.form.placeholder')"
                            :state="nameState"></b-form-input>
              <p class="warning-msg margin-warning" v-show="nameState == false"><i>{{ $t('ingestionProject.form.msgWarning') }}</i></p>
            </b-col>
            <p class="mt-3 new-ingestion-proyect-tip openSans-400 fz-13"><i>{{ $t('ingestionProject.form.tip') }}</i></p>
          </b-row>
        </div>
        <div v-else class="my-4">
          <h3 class="text-left openSans-100 fz-22 new-ingestion-proyect-name">{{ $t('proyect.general.ingestionSource') }}</h3>
          <b-row class="my-3 ml-0">
            <div class="d-flex align-items-center library-card-tag project-ingestion-source-filetype">
              <img :src="'/assets/images/icon_library_documenttype.svg'"/>
              <span>{{ $t('proyect.general.document') }}</span>
            </div>
            <span class="openSans-300 fz-12 my-auto mx-3"> {{ currentProject.files.length }} {{ $t('proyect.general.ingestedDocuments') }}</span>
          </b-row>
        </div>

        <b-row class="my-3">
          <b-col cols="4">
            <h3 class="text-left openSans-100 fz-22 new-ingestion-proyect-name">{{ $t('ingestionProject.step.titleCards') }}</h3>
          </b-col>
          <b-col cols="4" offset="4">
            <b-button class="openSans-600 fz-12 ta-center button-secondary btn-width float-right .new-ingestion-proyect-build-manifest-button" disabled>
              {{ $t('ingestionProject.step.btnManifest') }}
            </b-button>
          </b-col>
        </b-row>

        <b-row class="my-3" cols="1" cols-sm="2" cols-md="4" cols-lg="5">
          <b-col class="my-2" v-for="source in ingestionSources" :key="source.id">
            <ingestion-source
              :id="source.id"
              :icon-name="source.icon"
              :title="$t(source.title)"
              :available="source.available"
              :button-label="$t(source.buttonLabel)"
              :callback="uploadFiles"
              :availableName="nameState"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="currentStep > 1">
      <b-col>
        <div class="my-4">
          <h3 class="text-left create-ingestion-proyect-step-title openSans-100 fz-22" v-if="currentStep == 2">
            {{ $t('ingestionProject.step.generatePipeline') }}
          </h3>
          <h3 class="text-left create-ingestion-proyect-step-title openSans-100 fz-22" v-if="currentStep == 3">
            {{ $t('ingestionProject.step.configureManifest') }}
          </h3>
        </div>
        <b-row class="my-4">
          <b-col cols="6">
            <preloader :type="'pdf-preview'" v-if="preloader" />
            <preview-file :project-id="currentProject.projectId" :filename="currentProject.fileName" @preloader-display="displayPreloader"/>
          </b-col>
          <b-col cols="6">
            <div v-if="currentStep == 2">
              <p class="text-align-left">{{ $t('ingestionProject.step.choosePipeline') }}</p>
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-between">
                    <b-form-select class="pipeline-select" v-model="pipelineSelected" :options="pipelineOptionsByType" :disabled="manualLoading">
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled>{{ $t('ingestionProject.step.disableOptionSelect') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div v-show="pipelineSelected != 'manual'">
                      <b-button class="mx-2 button-primary openSans-600 fz-12 ta-center" disabled variant="success">
                        {{ $t('ingestionProject.step.btnConfigure') }}
                      </b-button>
                      <b-button class="mx-2 openSans-600 fz-12 ta-center button-secondary" variant="outline-secondary" @click="toStep(3)">
                        {{ $t('ingestionProject.step.btnNext') }}
                      </b-button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between my-3">
                    <div>
                      <b-form-radio-group id="radio-group-0" v-model="selectedTreatment" :options="optionsTreatment"></b-form-radio-group>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="m-3" v-if="pipelineSelected == 'manual'">
                <b-col>
                  <b-form-file
                    size="lg"
                    v-model="zipFile"
                    :placeholder="$t('ingestionProject.step.manual.placeholder')"
                    :drop-placeholder="$t('ingestionProject.step.manual.dropPlaceholder')"
                    accept=".zip,.rar,.7zip"
                    :disabled="manualLoading"
                    @input="saveManualMode"></b-form-file>
                </b-col>
              </b-row>
              <b-card class="mt-3" v-else>
                <pre class="text-align-left">{{ pipelineData }}</pre>
              </b-card>
            </div>
            <div v-if="currentStep == 3">
              <p class="text-align-left">{{ $t('ingestionProject.step.titleManifest') }}</p>
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-between">
                    <b-button-group>
                      <b-button variant="outline-success" title="Load file">
                        <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button variant="outline-success" title="New document">
                        <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button variant="outline-success" title="Add file">
                        <b-icon icon="file-plus" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button variant="outline-success" title="Save file">
                        <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                      </b-button>
                    </b-button-group>
                    <b-button class="button-primary openSans-600 fz-12 ta-center" variant="success" :disabled="loadingIngestion || sendSave" @click="testIngestion">
                      {{ $t('ingestionProject.step.btnTest') }}
                    </b-button>
                    <b-button class="button-primary openSans-600 fz-12 ta-center" variant="success" :disabled="sendSave || loadingIngestion" @click="saveProject">
                      {{ $t('ingestionProject.step.btnSave') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mt-3">
                <pre class="text-align-left">{{ { sources: manifestData } }}</pre>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div v-if="loadingIngestion" class="d-flex justify-content-center mb-3">
      <b-spinner></b-spinner>
    </div>

    <b-card v-if="ingestionTestDone" class="show-fade">
      <div class="d-flex justify-content-end">
        <b-button class="mx-1 button-primary openSans-600 fz-12 ta-center" @click="ingestionTestDone = false">
          {{ $t('ingestionProject.step.btnClose') }}
        </b-button>
      </div>
      <pre class="text-align-left">{{ ingestionData }}</pre>
    </b-card>

  </div>
</template>

<script>
import IngestionSource from '@/modules/projects/components/IngestionSource.vue'
import PreviewFile from '@/modules/common/components/PreviewFile.vue'
import Preloader from '@/modules/common/components/Preloader.vue'

import ProjectService from '@/modules/projects/services/ProjectService.js'
import IngestionService from '@/modules/common/services/IngestionService.js'
import SorceroIngestionService from '@/modules/common/services/SorceroIngestionService.js'


export default {

  name: 'UploadFilesSteps',

  components: {
    IngestionSource, PreviewFile, Preloader
  },
  props: {
    project: Object,
    creationMode: {
      type: Boolean,
      default: false
    },
    ingestionSources: Array,
    saveProjectCallback: Function
  },
  data () {
    return {
      currentStep: 1,
      zipFile: null,
      ingestionData: null,
      preloader: false,
      useLocalFile: false,
      manualLoading: false,
      ingestionTestDone: false,
      loadingIngestion: false,
      sendSave: false,
      pipelineData: {},
      pipelineSelected: '',
      pipelineType: '',
      selectedTreatment: 'single_document',
      files: [],
      manifestData: [],
      pipelineOptions: {},
      pipelineOptionsByType: [],
      currentProject: {},
      optionsTreatment: [
        { text: 'Single Document', value: 'single_document' },
        { text: 'Multiple Documents', value: 'collection_collection_passage' }
      ]
    }
  },
  methods: {
    displayPreloader(boolean) {
      this.preloader = boolean
    },
    uploadFiles(projectId, files, link) {
      this.preloader = true
      if (this.creationMode) {
        this.currentProject.projectId = projectId
        this.currentProject.botName = this.$router.currentRoute.params.bot
      }
      let currentFile = files[0]
      this.currentProject.fileName = currentFile.filename
      this.useLocalFile = link ? true : false
      this.files = files
      this.manifestData = this.files.map(file => file.manifest)
      this.pipelineType = this.manifestData[0].type
      this.toStep(2)
    },
    saveManualMode() {
      let allFilenames = this.files.map(file => file.filename)
      let project = {
        id: this.currentProject.projectId,
        bot_id: this.currentProject.botName,
        project_name: this.currentProject.name,
        source_files: allFilenames,
        documents: [],
        created: new Date(),
        user_id: this.$store.getters.getCurrentUser.userid,
        pipeline_configs: []
      }
      this.manualLoading = true
      ProjectService.saveManualMode(project, this.zipFile).then(() => {
        this.manualLoading = false
        this.$router.push(`/${this.project.botName}/projects`)
      })
    },
    saveProject() {
      let manifest = { sources: this.manifestData }
      let allFiles = this.files.map(file => file.filename)
      let project = {
        id: this.currentProject.projectId,
        bot_id: this.currentProject.botName,
        project_name: this.currentProject.name,
        source_files: allFiles,
        documents: [],
        created: new Date(),
        user_id: this.$store.getters.getCurrentUser.userid,
        pipeline_configs: [{
          name: this.pipelineData.name,
          type: "INGESTION",
          pipeline_data: this.pipelineData,
          manifest: manifest,
          last_run: new Date(),
          status: ""
        }]
      }
      this.sendSave = true
      this.saveProjectCallback()
      ProjectService.save(project, this.useLocalFile, this.selectedTreatment).then(() => {
        this.sendSave = false
        this.$router.push(`/${this.$router.currentRoute.params.bot}/projects`)
      })
    },
    testIngestion() {
      this.ingestionData = null
      this.loadingIngestion = true
      SorceroIngestionService.testIngestion(this.manifestData).then(response => {
        this.ingestionData = response.data
        this.loadingIngestion = false
        this.ingestionTestDone = true
      })
    },
    filterByTypeManifestData() {
      this.pipelineOptionsByType = []
      let optionsByFileType = this.pipelineOptions[this.pipelineType]
      let defaultPipeline = 'manual'
      let defaultTreatmentId = 'single_document'
      Object.values(optionsByFileType).forEach(pipeline => {
        if (pipeline.default) {
          defaultPipeline = pipeline.name
          defaultTreatmentId = pipeline.treatment_id
        }
        this.pipelineOptionsByType.push({ value: pipeline.name, text: pipeline.label })
      })
      this.pipelineOptionsByType.push({ value: 'manual', text: 'Manual' })
      this.pipelineSelected = defaultPipeline
      this.selectedTreatment = defaultTreatmentId
      this.pipelineData = optionsByFileType[defaultPipeline].pipeline
    },
    setPipelines() {
      IngestionService.getBasePipelines().then(response => {
        this.pipelineOptions = response.data
      })
    },
    toStep(stepNumber) {
      this.currentStep = stepNumber
      this.$emit("update-step", this.currentStep)
    }
  },
  watch: {
    manifestData() {
      this.filterByTypeManifestData()
    },
    pipelineSelected() {
      this.manifestData.forEach(source => {
        source['pipeline'] = this.pipelineSelected
      })
      let currentPipeline = this.pipelineOptions[this.pipelineType][this.pipelineSelected]
      this.selectedTreatment = currentPipeline.treatment_id
      this.pipelineData = currentPipeline.pipeline
    }
  },
  computed: {
    nameState() {
      return !this.creationMode || (this.currentProject.name != null ? this.currentProject.name.trim() != '' : null)
    }
  },
  created() {
    this.currentProject = this.project
    this.setPipelines()
  }
}
</script>

<style lang="css" scoped>

.btn-width {
  width: 215px;
}

.show-fade {
  z-index: 4000;
  min-height: 85vh;
  border-radius: 0px;
  box-shadow: 0px -5px 10px #888;
  top: 30%;
  left: 0%;
  position: absolute;
  max-width: 1440px;
  width: 100%;
}

</style>