<template>
  <b-card class="mb-2 new-ingestion-proyect-card">
    <div class="new-ingestion-proyect-card-content">
      <img :src="`/assets/images/${iconName}`">
      <b-card-title class="my-3 new-ingestion-proyect-card-title openSans-100 fz-20">{{ title }}</b-card-title>
      <b-button class="openSans-600 fz-12 ta-center button-secondary" :disabled="disabled || !this.$store.getters.canModifyLibrary" @click="showModal">{{ buttonLabel }}</b-button>
      <url-file-modal v-if="id == 'url'"
        :id="id"
        :title="title"
        :icon-name="iconName"
        :project-id="projectId"
        :callback="callback"/>
      <upload-file-modal v-else
        :id="id"
        :title="title"
        :icon-name="iconName"
        :project-id="projectId"
        :callback="callback"/>
    </div>
  </b-card>
</template>

<script>
import UploadFileModal from "@/modules/common/components/UploadFileModal.vue"
import UrlFileModal from "@/modules/common/components/UrlFileModal.vue"
import store from "@/store";
import {PERM_MODIFY_LIBRARY} from "@/modules/security/services/SecurityService";

export default {

  name: 'IngestionSource',

  data () {
    return {
      disabled: false
    }
  },
  components: {
    UploadFileModal, UrlFileModal
  },
  props: {
    id: String,
    iconName: String,
    title: String,
    buttonLabel: String,
    available: Boolean,
    callback: Function,
    availableName: Boolean,
    projectId: String
  },
  methods: {
    showModal() {
      let disabledName = !this.availableName
      if(disabledName) {
        this.$bvModal.hide(this.id)
      } else {
        this.$bvModal.show(this.id)
      }
    },
    canOnlyRead() {
      return store.getters.getInstancePermissions.indexOf(PERM_MODIFY_LIBRARY) != -1
    }
  },
  created() {
    this.disabled = !this.available;
  }
}
</script>